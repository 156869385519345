@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
@import url("overlayscrollbars/styles/overlayscrollbars.css");

/* Background color to ensure overscroll remains colored */
html {
  @apply bg-background-secondary;
}

/* Background color for main page */
body {
  @apply bg-background;
}

.button {
  @apply text-text;
  @apply bg-accent;
  @apply flex;
  @apply items-center;
  @apply hover:bg-accent-secondary;
  @apply rounded-full;
  @apply sm:text-xl text-base;
  @apply px-8;
  @apply py-2;
  @apply text-center;
}

.button-small {
  @apply text-text;
  @apply bg-accent;
  @apply flex;
  @apply items-center;
  @apply hover:bg-accent-secondary;
  @apply rounded-full;
  @apply lg:text-lg text-base;
  @apply px-4;
  @apply lg:py-1 py-0.5;
}

.title {
  @apply text-text;
  @apply font-bold;
  @apply text-2xl;
  @apply border-b-4;
  @apply border-accent;
  @apply w-fit;
  @apply pr-4;
}

.page-section {
  @apply max-w-[1000px];
  @apply w-full;
  @apply mx-auto;
  @apply p-4 sm:p-10;
  @apply h-full;
  @apply sm:mb-24 mb-16;
  @apply bg-background-secondary;
  @apply rounded-lg;
}

.project-media {
  @apply rounded-lg;
  @apply shadow-text;
  @apply shadow-md;
  @apply md:max-w-[600px];
  @apply w-full;
  @apply mx-auto;
}

.about-me-image {
  @apply rounded-lg;
  @apply shadow-shadow shadow-md;
  @apply object-cover;
}

.about-me-section {
  @apply grid items-stretch;
  @apply grid-flow-dense;
  @apply lg:gap-8 gap-4;
  @apply sm:px-20 lg:px-40;
}
